import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Container, Row, Col } from "reactstrap";


function IncomeTax() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        {/* <IndexHeader /> */}
        <div className="main">
          <>
            <div className="section">
              <Container className="text-center">
                <Row className="justify-content-md-center">
                  <Col lg="8" md="12">
                    <h4 className="title text-justify">Income Tax</h4>
                    <h5 className="description text-justify">
                          <p>To smoothen the task of our clients, we provide accounting services to our small and medium size clients. Here, they get systematic and hassle free knowledge based service.</p>

                          <p><b>Income Tax Services:</b></p>
                          <p>Unlike previously, tax compliance these days has become complicated and time consuming.</p>
                          <p>GST is levied each stage in the supply chain, where a transaction takes place.</p>

                          <p><b>The indicative areas of services are:</b></p>
                          <ul>
                            <li>Applying for PAN, TAN & all other Applications</li>
                            <li>Tax Planning & Advisory</li>
                            <li>Preparation and e-filing of all types of Tax Returns</li>
                            <li>Advance Tax Calculation and Investment planning</li>
                            <li>Representing Assessee before Income Tax Authorities</li>
                            <li>Representation before the appellate authorities</li>
                            <li>Registration of Trust under the Income Tax Act</li>
                            <li>Obtain Exemption certificate for Charitable Institutions</li>
                            <li>Obtaining various tax exemption certificates</li>
                            <li>Advising non-residents on Income Tax Laws & relevant disclosures in India and abroad</li>
                            <li>All other services related to the Income Tax Act0p2</li>
                          </ul>
                          
                     </h5>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default IncomeTax;
