import React from "react";

import { Container, Row, Col } from "reactstrap";


function ContactUsView() {
 
  return (
  
    <>
      <div className="section">
        <Container className="text-center">
          
          <Row className="justify-content-md-center">
            <Col lg="4" md="4">
              <h4 className="title text-justify">Head Office: </h4>
              <h5 className="description text-justify">
                <p>
                  307, Radhaswami Sumit,<br /> Gopi Cinema Road,<br /> Anand- 388 001, Gujarat, India <br />
                </p>
              </h5>
            </Col>
            <Col lg="4" md="4">
              <h4 className="title text-justify">Contact No: </h4>
              <h5 className="description text-justify">
                <p>
                  +91-9979282919 <br />
                  +91-9081109242 <br />
                </p>
              </h5>
            </Col>
            <Col lg="4" md="4">
              <h4 className="title text-justify">Email Connect: </h4>
              <h5 className="description text-justify">
                <p>
                <a href="mailto:jayeshagrawalandco@gmail.com">jayeshagrawalandco@gmail.com</a> <br />
                </p>
              </h5>
            </Col>
          </Row>
          
        </Container>
      </div>
    </>
  
  );
}

export default ContactUsView;
