import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Container, Row, Col } from "reactstrap";


function Auditing() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        {/* <IndexHeader /> */}
        <div className="main">
          <>
            <div className="section">
              <Container className="text-center">
                <Row className="justify-content-md-center">
                  <Col lg="8" md="12">
                    <h4 className="title text-justify">Auditing</h4>
                    <h5 className="description text-justify">
                          <p>Today’s world of auditing services covers not only the traditional audits which have been the key area of auditing since years, 
                            but also includes variety of audits conducted at regular intervals based on the applicable accounting and auditing standards, 
                            relevant laws and the policies and procedures of the entities.</p>
                          <p>Being the key area of work for any Chartered Accountant firm,
                             we have expertise in conducting various kinds of audits with the support of our well trained and enthusiastic team.</p>

                          <p><b>Following is the list of audits we are engaged into:</b></p>
                          <ul>
                            <li>Statutory Audit</li>
                            <li>Tax Audit</li>
                            <li>Internal Audit/Concurrent Audit</li>
                            <li>Management Audit</li>
                          </ul>
                          
                     </h5>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Auditing;
