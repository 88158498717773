import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Container, Row, Col } from "reactstrap";


function GstService() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        {/* <IndexHeader /> */}
        <div className="main">
          <>
            <div className="section">
              <Container className="text-center">
                <Row className="justify-content-md-center">
                  <Col lg="8" md="12">
                    <h4 className="title text-justify">GST (Goods And Service Tax)</h4>
                    <h5 className="description text-justify">
                          <p>GST is a huge reform for indirect taxation in India, the likes of which the country has not seen post Independence. GST will simplify indirect taxation, reduce complexities, and remove the cascading effect. Experts believe that it will have a huge impact on businesses both big and small, and change the way the economy functions.</p>

                          <p>The ebook will help you understand the basics of GST, important terminologies and concepts, and how this might affect your business in the long run. Students of finance, business professionals, entrepreneurs, tax practitioners and accountants will find this e-book comprehensive and helpful in understanding GST.</p>

                          <p><b>Comprehensive:</b></p>
                          <p>GST will subsume all of the current indirect taxes. Plus, by bringing in a unified taxation system, across the country, it will ensure that there is no more arbitrariness in tax rates.</p>

                          <p><b>Multi-stage:</b></p>
                          <p>GST is levied each stage in the supply chain, where a transaction takes place.</p>

                          <p><b>Value-addition:</b></p>
                          <p>This is the process of addition to the value of a product/ service at each stage of its production, exclusive of initial costs. Under GST, the tax is levied only on the value added.</p>

                          <p><b>Destination-based consumption:</b></p>
                          <p>Unlike the current indirect taxes, GST will be collected at the point of consumption. The taxing authority with appropriate jurisdiction in the place where the goods/ services are finally consumed will collect the tax.</p>

                          <p>For example: Let’s say that cotton garments are being shipped from Karnataka to Maharashtra. Karnataka is the producer state and Maharashtra is the consumer state. Tax revenue under GST will go to Maharashtra.</p>

                          <p>Let’s understand how this will impact imports and exports. Exports are not taxable, because the place of consumption is outside India. Imports are taxable, because the place of consumption is in India.</p>

                          <p>The tax on imported goods will therefore be just the same as domestically-produced goods. Thus, the export industry will become more competitive when compared to its international peers. Also, domestic goods will be protected by making imports at par with domestic goods.</p>

                          <p><b>What is SGST, CGST and IGST?</b></p>
                          <p>Suppose goods worth INR 10,000 are sold by manufacturer A in Maharashtra to Dealer B in Maharashtra. B resells them to trader C in Rajasthan for INR 17,500. Trader C finally sells to end user D in Rajasthan for INR 30,000.</p>

                          <p>Suppose CGST= 9%, SGST=9%. Then, IGST= 9+9=18%</p>

                          <p> *** Any IGST credit will first be applied to set off IGST then CGST. Balance will be applied to setoff SGST.</p>

                          <p> Since, GST is a consumption based tax, i.e., the state where the goods were consumed will collect GST. By that logic, Maharashtra (where goods were sold) should not get any taxes.</p>

                          <p>Rajasthan and Central both should have got (30,000 * 9%) = 2,700 each instead of only 2,250.</p>

                          <p>Maharashtra (exporting state) will transfer to the Centre the credit of SGST of INR 900 used in payment of IGST.</p>

                          <p>The Centre will transfer to Rajasthan (importing state) INR 450 as IGST credit used.</p>

                          <p>**Note: Custom duties are not part of this tax structure.</p>

                          <p><b>When is Tax Levied?</b></p>
                          <p>A taxable event such as manufacture, sale and provision of a good has to occur for tax to be collected. Under the current system, each taxable event is subject to multiple taxes such as excise, VAT/ CST and service tax. But under GST, products will no longer have multiple taxes, and will not incur excise duty as well as VAT at different points of time. There will no longer be any difference between goods and services in terms of taxation.</p>

                          <p>An example of this is when we go out to eat at a restaurant. Earlier, the customer paid both VAT and service tax on a single bill, but after GST there is single tax charged on the bill amount.</p>

                          <p>This leads us to an important concept in GST - Time, Place, and Value of Supply of goods and services.</p>
                    </h5>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default GstService;
