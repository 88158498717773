import React from "react";

import { Container, Row, Col } from "reactstrap";


function AboutUsView() {
  return (
    <>
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h4 className="title text-justify">We, at Jayesh Agrawal & CO. has been working since 2004 as an Income tax & Sales Tax consultant. </h4>
              <h5 className="description text-justify">
                <p>
                To cope up with the legal matters and diversify our work and to cover up large number of clients we also taken professional services from many chartered accounting firm, to provide our clients best of best services at a single place.
                </p><p>
We are a Knowledge Process Outsourcing firm from India. Our domain is Finance and Accounting processes. Our focus is on the area of our expertise. We concentrate our attention on a limited spectrum of finance and accounting processes, but we endeavor to deliver better than the best.
</p>
              </h5>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col lg="6" md="6">
              <h3 className="title text-justify text-center">Our Vision</h3>
              <h5 className="description text-justify">
                <p>Our firm continuously strives to be the Premier Accounting, Auditing and Tax Consultancy firm that provides excellent service to our clients and an excellent quality of life for our associates.
                </p>
              </h5>
            </Col>
            <Col lg="6" md="6">
              <h3 className="title text-justify text-center">Our Mission</h3>
              <h5 className="description text-justify">
                <p>We will predominantly work with organisations in the Manufacturing and Commercial sectors as partners to help them achieve their desired outcomes.
                </p>
              </h5>
            </Col>
            <Col lg="6" md="6">
              <h3 className="title text-justify text-center">Our Objectives</h3>
              <h5 className="description text-justify">
                <p>We are committed to creating and sustaining long-term relationships which drawn on our experience and expertise to help our clients achieve real success.
                </p>
              </h5>
            </Col>
            <Col lg="6" md="6">
              <h3 className="title text-justify text-center">Excellence in Service</h3>
              <h5 className="description text-justify">
                <p>To not just meet but exceed client expectation consistently by imbibing Teamwork, Professionalism, Personalised Service & Specialisation.
                </p>
              </h5>
            </Col>
          </Row>
          
        </Container>
    </>
  );
}

export default AboutUsView;
