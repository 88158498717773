import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Container, Row, Col } from "reactstrap";


function Business() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        {/* <IndexHeader /> */}
        <div className="main">
          <>
            <div className="section">
              <Container className="text-center">
                <Row className="justify-content-md-center">
                  <Col lg="8" md="12">
                    <h4 className="title text-justify">Business Support Services</h4>
                    <h5 className="description text-justify">
                      <p><b>Business and Financial Planning:</b></p>
                          
                          <ul>
                            <li>Preparation of Project Report, budgets, cash flow statements</li>
                            <li>Assessment of business financial requirements, duration of required finances and based on that, 
                              advising the best available source of finance</li>
                            <li>Conducting project feasibility study</li>
                            <li>End-to-end Consulting Services for Business Structuring, Establishment & Operations</li>
                          </ul>

                      <p><b>BPO / KPO Services :</b></p>
                     
                      <ul>
                        <li>Filing of Income Tax Returns</li>
                        <li>Consultancy on investment planning</li>
                        <li>Tax planning of income in India based on government rules and regulations</li>
                      </ul>

                      <p><b>Service Tax  :</b></p>
                      <p>With the increased scope and complexity of this area of Indirect Tax, 
                        business has become more complicated in view of compliance. 
                        Our experts on Service Tax guide the clients on the following areas:</p>

                      <ul>
                        <li>Registration</li>
                        <li>Classification of service</li>
                        <li>Payment of tax</li>
                        <li>Filing of return</li>
                        <li>Adjustment of excess service tax paid</li>
                        <li>Service tax credit</li>
                        <li>Assessment</li>
                      </ul>
                      <p><b>VAT Services  :</b></p>
                     
                      <ul>
                        <li>VAT Registration</li>
                        
                        <li>VAT Audit</li>
                        <li>Planning under VAT</li>
                      </ul>
                    </h5>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Business;
