/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <footer className="footer">
      <Container>
        <nav>
        <ul>
            <li>
              <Link to ="/index" >
                Jayesh Agrawal & CO
              </Link>
            </li>
            <li>
            <Link to ="/aboutus" >
                About Us
              </Link>
            </li>
            <li>
              <Link to ="/contactus" >
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()} {" "}
          <a
            href="https://www.creative-tim.com?ref=nukr-dark-footer"
            target="_blank"
          >
            Jayesh Agrawal & CO
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

export default TransparentFooter;
