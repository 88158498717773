/*eslint-disable*/
import React, { useState } from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components

function IndexHeader(props) {
  let pageHeader = React.createRef();
  const [page, setPage] = useState('index');
  
  
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
    if(props.page) {
      setPage(props.page)
    }
  });

  return (
    <>
    {page != 'index' ?  
    <div className="simple-header" filter-color="blue">
        <Container>
          <div className="content-center brand">
            <h1 className="page-title">{page.toUpperCase()}</h1>
           
          </div>
        </Container>
      </div>
      : 
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/header.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <h1 className="h1-seo">Chartered Accounts.</h1>
            <h3>Everything you need for your Legals.</h3>
            <ul className="header-list">
              <li>GST</li>
              <li>TAX</li>
              <li>FINANCE</li>
              <li>BUSINESS</li>
              <li>AUDITING</li>
            </ul>
          </div>
        </Container>
      </div>
  }
    </>
  );
}

export default IndexHeader;
